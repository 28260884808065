



















import Vue from 'vue';
// @ts-ignore
import AnchorPopup from '@/plugins/anchorPopup/installer.js';
import Snotify, { SnotifyPosition } from 'vue-snotify';
Vue.use(Snotify, {
  toast: {
    timeout: 5000,
    titleMaxLength: 32,
    position: SnotifyPosition.rightTop,
  },
});

// @ts-ignore
import initClient from '@/app/initClient';
import mixins from 'vue-typed-mixins';
import FixedToTop from '@/modules/base/components/statusBtn/fixedToTop.vue';
import FixedBack from 'modules/base/components/statusBtn/fixedBack.vue';
export default mixins(initClient).extend({
  components: { FixedBack, FixedToTop },
  metaInfo() {
    return {
      titleTemplate: (titleChunk) => {
        // @ts-ignore
        return titleChunk ? `${titleChunk} | ${this.siteName}` : this.siteName;
      },
    };
  },
  created() {
    // @ts-ignore
    Vue.use(AnchorPopup, this.anchorPopupDefaultConfig);
  },
  async mounted() {
    // @ts-ignore
    await this.initClient();
  },
  methods: {
    reload() {
      window.location.reload();
    },
  },
  computed: {
    anchorPopupDefaultConfig() {
      return {
        width: '350',
        applyLabel: this.$t('action.apply'),
        closeLabel: this.$t('action.close'),
      };
    },
    siteName() {
      return this.$store.getters['base/siteName'];
    },
    currentUrl() {
      return window.location.href;
    }
  },
});
