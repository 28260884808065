import qs from 'qs'
import fingerprintjs from 'fingerprintjs'

class oauth {
  login(data) {
    const {
      provider, // google, facebook, line, apple
      clientKey,
      successPath, // 登入成功後導向頁面
      metaData,
      keepLogin,
      isDebugMode,
      redirectUri, // 自訂redirectUri, 沒有則為登入的redirectUri
      returnUri,
    } = data

    this.isDebugMode = isDebugMode === true
    this.keepLogin = keepLogin === true
    this.successPath = successPath || null
    this._setupRedirectUri(returnUri)
    if(redirectUri) this.redirectUri = redirectUri

    let url
    const getUrlMethod = (clientKey, metaData) => {
      return this[`_${provider}LoginUrl`](clientKey, metaData)
    }
    if(typeof getUrlMethod != 'function') {
      throw new Error(`oauth provider ${provider} undefined`)
      return
    }
    url = getUrlMethod(clientKey, metaData)
    window.location.assign(url)
  }

  _setupRedirectUri(returnUri = null) {
    if(returnUri) {
      this.redirectUri = returnUri
      return
    }
    const host = `${window.location.origin}`
    const href = window.rootVm.$router.resolve({
      name: returnUri || 'line-login-return',
    }).href
    this.redirectUri = `${host}${href}`
  }

  _getOauthState(metaData = null) {
    const fingerprint = new fingerprintjs().get()
    const timestamp = (new Date()).getTime()
    let state = {
      device: fingerprint,
      timestamp,
      redirectUri: this.redirectUri,
      keepLogin: this.keepLogin,
      isDebugMode: this.isDebugMode,
      ...metaData,
    }
    if(this.successPath) {
      state.successPath = this.successPath
    }
    return state
  }

  _googleLoginUrl(clientKey, metaData = null) {
    const baseUrl = 'https://accounts.google.com/o/oauth2/v2/auth'
    let query = {
      client_id: clientKey,
      response_type: 'token',
      scope: 'openid email https://www.googleapis.com/auth/userinfo.profile',
      state: JSON.stringify(this._getOauthState(metaData)),
      redirect_uri: this.redirectUri,
    }
    const queryString = qs.stringify(query, { encode: false })
    return `${baseUrl}?${queryString}`
  }

  _facebookLoginUrl(clientKey, metaData = null) {
    const baseUrl = 'https://www.facebook.com/v7.0/dialog/oauth'
    let query = {
      client_id: clientKey,
      response_type: 'token',
      scope: 'public_profile email',
      state: JSON.stringify(this._getOauthState(metaData)),
      redirect_uri: this.redirectUri,
    }
    const queryString = qs.stringify(query, { encode: false })
    return `${baseUrl}?${queryString}`
  }

  _lineLoginUrl(clientKey, metaData = null) {
    const baseUrl = 'https://access.line.me/oauth2/v2.1/authorize'

    // line登入頁如果url帶 { } 符號會400, 所以要先去掉頭尾的 { }
    let stateString = JSON.stringify(this._getOauthState(metaData))
    stateString = stateString.slice(0, -1)
    stateString = stateString.substring(1)
    let query = {
      client_id: clientKey,
      response_type: 'code', // line的oauth response type只接受code flow
      scope: 'profile openid email',
      state: stateString,
      redirect_uri: this.redirectUri,
    }
    const queryString = qs.stringify(query, {
      encode: false,
    })
    return `${baseUrl}?${queryString}`
  }

  getReturnData(provider) {
    const _getReturnData = () => this[`_${provider}ReturnData`]()
    if(typeof _getReturnData != 'function') {
      throw new Error(`oauth provider ${provider} undefined`)
      return
    }

    return _getReturnData()
  }

  _getOriginCallbackDataObject(data) {
    try {
      let result = qs.parse(data)
      return result
    } catch (e) {
      console.error(e)
      return null
    }
  }

  _googleReturnData() {
    const originData = this._getOriginCallbackDataObject(window.rootVm.$route.hash)
    if(!originData) return null

    let result = {
      ...originData,
    }
    result.access_token = originData.access_token
    result.state = JSON.parse(originData['#state'])
    return result
  }

  _facebookReturnData() {
    const originData = this._getOriginCallbackDataObject(window.rootVm.$route.hash)
    if(!originData) return null

    let result = {
      ...originData,
    }
    result.access_token = originData['#access_token']
    result.state = JSON.parse(originData.state)
    return result
  }

  _lineReturnData() {
    const originData = this._getOriginCallbackDataObject(window.rootVm.$route.query)
    if(!originData) return null

    let result = {
      ...originData,
    }

    // 補回登入頁面砍掉的{ }
    result.state = JSON.parse(`{${originData.state}}`)
    return result
  }
}

export default new oauth()
